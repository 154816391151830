.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1c1228db;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ConnectButton {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  padding: 10px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  min-width:  200px;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
}

.ConnectButton:hover {
  background-color: rgba(255, 255, 255, 0.12);
}

.ConnectButton:disabled {
  background-color: grey;
  color: black;
}

.coinview-header {
  background: linear-gradient(
      180deg,
      rgba(41, 101, 255, 0.1) 0%,
      rgba(41, 101, 255, 0) 51.64%
    ),
    linear-gradient(0deg, #292929, #292929), #ffffff;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
  padding: 30px;
}
