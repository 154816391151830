/*form styles*/
#msform {
  text-align: center;
  position: relative;
  margin-top: 30px;
}

#msform #field {
  text-align: left;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  padding: 20px 30px;
  box-sizing: border-box;
  width: 80%;
  margin: 0 10%;

  /*stacking fieldsets above each other*/
  position: relative;
}

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
  display: none;
}

/*inputs*/
#msform input, #msform textarea {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2C3E50;
  font-size: 13px;
}

#msform input:focus, #msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #ee0979;
  outline-width: 0;
  transition: All 0.5s ease-in;
  -webkit-transition: All 0.5s ease-in;
  -moz-transition: All 0.5s ease-in;
  -o-transition: All 0.5s ease-in;
}

/*buttons*/
#msform .action-button {
  width: 100px;
  background: #2f2fe4;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button:hover, #msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #ee0979;
}

#msform .action-button-previous {
  width: 100px;
  background: #C5C5F1;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button-previous:hover, #msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #C5C5F1;
}
