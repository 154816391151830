body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p.ActHead5,
li.ActHead5,
div.ActHead5 {
  mso-style-name: "ActHead 5\,s";
  mso-style-link: "ActHead 5 Char\,s Char";
  margin-top: 14pt;
  margin-right: 0cm;
  margin-bottom: 0cm;
  margin-left: 2cm;
  margin-bottom: 0.0001pt;
  text-indent: -2cm;
  page-break-after: avoid;
  font-size: 12pt;
  font-family: "Times New Roman", "serif";
  font-weight: bold;
}

p.SubsectionHead,
li.SubsectionHead,
div.SubsectionHead {
  mso-style-name: "SubsectionHead\,ssh";
  margin-top: 12pt;
  margin-right: 0cm;
  margin-bottom: 0cm;
  margin-left: 2cm;
  margin-bottom: 0.0001pt;
  page-break-after: avoid;
  font-size: 11pt;
  font-family: "Times New Roman", "serif";
  font-style: italic;
}

p.TableHeading,
li.TableHeading,
div.TableHeading {
  mso-style-name: "TableHeading\,th";
  margin-top: 3pt;
  margin-right: 0cm;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-bottom: 0.0001pt;
  line-height: 12pt;
  page-break-after: avoid;
  font-size: 10pt;
  font-family: "Times New Roman", "serif";
  font-weight: bold;
}

p.Tabletext,
li.Tabletext,
div.Tabletext {
  mso-style-name: "Tabletext\,tt";
  mso-style-link: "Tabletext Char\,tt Char";
  margin-top: 3pt;
  margin-right: 0cm;
  margin-bottom: 0cm;
  margin-left: 0cm;
  margin-bottom: 0.0001pt;
  line-height: 12pt;
  font-size: 10pt;
  font-family: "Times New Roman", "serif";
}

p.subsection,
li.subsection,
div.subsection {
  mso-style-name: "subsection\,ss";
  mso-style-link: "subsection Char\,ss Char";
  margin-top: 9pt;
  margin-right: 0cm;
  margin-bottom: 0cm;
  margin-left: 2cm;
  margin-bottom: 0.0001pt;
  text-indent: -2cm;
  font-size: 11pt;
  font-family: "Times New Roman", "serif";
}

p.notetext,
li.notetext,
div.notetext {
  mso-style-name: "note\(text\)\,n";
  mso-style-link: "note\(text\) Char\,n Char";
  margin-top: 6.1pt;
  margin-right: 0cm;
  margin-bottom: 0cm;
  margin-left: 99.25pt;
  margin-bottom: 0.0001pt;
  text-indent: -42.55pt;
  font-size: 9pt;
  font-family: "Times New Roman", "serif";
}

p.subsection,
li.subsection,
div.subsection {
  mso-style-name: "subsection\,ss";
  mso-style-link: "subsection Char\,ss Char";
  margin-top: 9pt;
  margin-right: 0cm;
  margin-bottom: 0cm;
  margin-left: 2cm;
  margin-bottom: 0.0001pt;
  text-indent: -2cm;
  font-size: 11pt;
  font-family: "Times New Roman", "serif";
}

p.paragraphsub,
li.paragraphsub,
div.paragraphsub {
  mso-style-name: "paragraph\(sub\)\,aa";
  mso-style-link: "paragraph\(sub\) Char\,aa Char";
  margin-top: 2pt;
  margin-right: 0cm;
  margin-bottom: 0cm;
  margin-left: 104.9pt;
  margin-bottom: 0.0001pt;
  text-indent: -104.9pt;
  font-size: 11pt;
  font-family: "Times New Roman", "serif";
}

p.paragraph,
li.paragraph,
div.paragraph {
  mso-style-name: "paragraph\,a";
  mso-style-link: "paragraph Char\,a Char";
  margin-top: 2pt;
  margin-right: 0cm;
  margin-bottom: 0cm;
  margin-left: 82.2pt;
  margin-bottom: 0.0001pt;
  text-indent: -82.2pt;
  font-size: 11pt;
  font-family: "Times New Roman", "serif";
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  opacity: 1;
  border-radius: 10px;
  background-color: #999999;
  background-clip: content-box;
}

*:hover::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  background-clip: content-box;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: #999999 !important;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #999999 !important;
}

.btn-primary {
  background-color: #2895EB !important;
  border-color: #2895EB !important;
}

.btn-outline-primary {
  border-color: #2895EB !important;
  color: #2895EB !important;
}

.btn-outline-primary:hover {
  background-color: #2895EB !important;
  border-color: #2895EB !important;
  color: #fff !important;
}

.btn {
  padding: 0.25rem 1rem !important;
  border-radius: 32px !important;
}

.card {
  border-radius: 4px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  font-size: 14px !important;
}

.card-body {
  padding: 1rem !important;
}

.table-primary {
  --bs-table-bg: #2895EB !important;
}

.table-rows-warning td {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.table-rows-success td {
  background-color: var(--bs-success-bg-subtle) !important;
}

.table-rows-danger td {
  background-color: var(--bs-danger-bg-subtle) !important;
}

table th {
  font-weight: 500 !important;
  color: #fff !important;
}

.circle-icons {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #2895EB !important;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    font-size: 15px;
  }
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.active>.page-link,
.page-link.active {
  background-color: #2895EB !important;
  border-color: #2895EB !important;
  color: #fff !important;
}

.page-link {
  color: #2895EB !important;
}

.banner-wrapper {
  margin-left: -64px;
  margin-right: -64px;
}

.banner-wrapper img {
  height: 320px;
  width: 100%;
  object-fit: cover;
}

.banner-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at calc(50% - 830px) 0px, #2e3a40 150px, #042c5f 415px, #0a5d9c 800px);
  opacity: 80%;
}

.banner-content {
  top: 7.5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
}

.table {
  border: 1px solid #dee2e6;
  font-size: 14px !important;
}

body .text-primary {
  color: #2895EB !important;
}

.top-card {
  margin-top: -4rem;
}

.top-card .card {
  box-shadow: none;
}

.top-card .card::before {
  content: "";
  position: absolute;
  height: 100%;
  border-left: 2px solid #2895EB;
  border-radius: 4px 0 0 4px;
}

.top-card.card .card .card-body {
  padding: 0.5rem 1.25rem !important;
}

.card.active {
  background-color: #2895EB;
  color: #fff;
}

.card.active::before {
  content: "";
  position: absolute;
  height: 100%;
  border-left: 2px solid #2895EB;
  border-radius: 4px 0 0 4px;
}

body .bg-primary-subtle {
  background-color: #2897eb47 !important;
}

.form-control {
  color: #8c8c8c !important;
}

.form-select {
  color: #8c8c8c !important;
}

::placeholder {
  color: #8c8c8c !important;
}

/* modal ui  */
.modal-scroll-vh {
  max-height: calc(100vh - 300px);
}

.item-name {
  display: inline-block;
  width: 49%;
}

.item-value {
  display: inline-block;
  width: 50%;
  text-align: end;
}


.bg-green {
  background-color: #0fa631;
}

.bg-red {
  background-color: #ca1414;
}

.bg-gray {
  background-color: #a8a8a8;
}

.border-blue {
  border: 1px solid #2895eb;
}

.edit-btn {
  background-color: #84a4c2;
}

.min-height {
  min-height: 265px;
  min-width: 380px;
}

.no-rec {
  padding: 0px 50px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.box-sec ul {
  padding: 0;
  text-decoration: none;
  list-style: none;
  padding: 12px;
}

.box-sec ul li {
  margin-bottom: 3px;
}

.box-sec .box1 {
  border: 1px solid #0fa631;
  border-radius: 15px;
  overflow: scroll;
  padding-bottom: 0px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box-sec .box1 h3 {
  font-family: Arial, sans-serif;
  font-size: 15px;
  color: #0fa631;
  margin: 0;
  padding: 0;
}

.box-sec .box1 span {
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #a8a8a8;
}

.bottom-footer {
  background-color: #0fa631;
  margin: 0;
  padding: 3px;
  margin-top: auto;
}

.bottom-footer h6 {
  color: #fff;
  margin-bottom: 0px;
}





.box-sec .box2 {
  border: 1px solid #ca1414;
  border-radius: 15px;
  overflow: scroll;
  padding-bottom: 0px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box-sec .box2 h3 {
  font-family: Arial, sans-serif;
  font-size: 15px;
  color: #ca1414;
  margin: 0;
  padding: 0;
}

.box-sec .box2 span {
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #a8a8a8;
}

.bottom-footer2 {
  background-color: #ca1414;
  margin: 0;
  padding: 2px;
  margin-top: auto;
}

.bottom-footer2 h6 {
  color: #fff;
  margin-bottom: 0px;
}






.box-sec .boxgray {
  border: 1px solid #a8a8a8;
  border-radius: 15px;
  overflow: scroll;
  padding-bottom: 0px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Ensures uniform height for all boxes */
}

.box-sec .boxgray h3 {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #a8a8a8;
  margin: 0;
  padding: 0;
  max-height: 200px;
  overflow-y: scroll;
}

.box-sec .boxgray span {
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
}



.box-sec .boxblue {
  border: 1px solid #2895eb;
  border-radius: 15px;
  overflow: scroll;
  padding-bottom: 0px;
  margin-right: 30px;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  max-height: 200px;
  /* overflow-y: auto; */
  overflow-x: hidden;
}

.box-sec .boxblue h3 {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #a8a8a8;
  margin: 0;
  padding: 0;
}



.bottom-footer3 {
  background-color: #a8a8a8;
  padding: 3px;
}

.bottom-footer3 h6 {
  margin-bottom: 0px;
  color: #fff;
}



.bottom-footer4 {
  background-color: #2895eb;
  padding: 3px;
  margin-right: 24px;
  width: 100%;
  border-radius: 10px;
  margin-top: 10px;
}

.bottom-footer4 h6 {
  margin: 0;
  color: #fff;
}

.modal-body {
  height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
}


.fixed-box {
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  width: 200px;
}

.no-content {
  text-align: center;
  color: #888;
}

.cards-parent .card-wrapper {
  border: 1px solid #2895eb;
  border-radius: 6px !important;
  margin-bottom: 1rem;
}

.cards-parent .card-scroll {
  height: 200px;
  overflow-y: auto;
}

.cards-parent .card-bottom {
  background-color: #2895eb;
  color: #fff;
  border-radius: 0 0 6px 6px;
}

.cards-parent h3 {
  font-size: 1rem;
  font-weight: 500;
}

.card-no-rec {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}